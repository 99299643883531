import React, { useRef, useMemo, useEffect, useState } from "react";
import seedrandom from "seedrandom";

import { tailwindCascade } from "@/helpers/tailwindCascade";

import {
	REVEAL_TYPE_BLUR,
	REVEAL_TYPE_GRID_3X4,
	REVEAL_TYPE_GRID_3X4_SEQUENTIAL,
	REVEAL_TYPE_GRID_6X8,
	REVEAL_TYPE_GRID_6X8_SEQUENTIAL,
	REVEAL_TYPE_IRIS,
	REVEAL_TYPE_ZOOM_20X,
} from "@/constants";

function Grid({ numRows, numCols, children, className, progress, seed = 123, ...props }) {
	const gridContainerRef = useRef();

	const order = useMemo(() => {
		const arr = [...Array(numRows * numCols).keys()];

		if (seed !== null) {
			// Shuffle
			const rng = seedrandom(seed);
			let i = arr.length;
			while (--i > 0) {
				const j = Math.floor(rng() * (i + 1));
				const t = arr[j];
				arr[j] = arr[i];
				arr[i] = t;
			}
		}

		return arr;
	}, [seed, numRows, numCols]);

	const numVisible = progress * order.length;

	return (
		<div className={tailwindCascade("inline-block relative w-full h-full", className)}>
			{children}
			<div ref={gridContainerRef} className="absolute inset-0 flex overflow-hidden pointer-events-none">
				{order.map((i, j) => (
					<div
						key={j}
						className={tailwindCascade("absolute", "opacity-0", "bg-background", {
							"opacity-100": j >= numVisible,
							"transition-opacity duration-700": progress > 0,
						})}
						style={{
							left: `calc(${((i % numCols) / numCols) * 100}% - 1px)`,
							top: `calc(${(Math.floor(i / numCols) / numRows) * 100}% - 1px)`,
							width: `calc(${(1 / numCols) * 100}% + 2px)`,
							height: `calc(${(1 / numRows) * 100}% + 2px)`,
						}}
					>
						<span className="bg-opacity-20 block w-full h-full bg-black"></span>
					</div>
				))}
			</div>
		</div>
	);
}

function Zoom({ className, children, progress, ...props }) {
	const ref = useRef(null);
	const scale = useMemo(() => 20 ** (1 - progress), [progress]);

	useEffect(() => {
		if (ref.current) {
			ref.current.style.transform = `scale(${scale})`;
		}
	}, [scale]);

	return (
		<div ref={ref} className={tailwindCascade("w-full h-full p-0 m-0", className)} {...props}>
			{children}
		</div>
	);
}

function Iris({ className, children, progress, ...props }) {
	const ref = useRef(null);
	const [size, setSize] = useState(0);

	useEffect(() => {
		if (ref.current) {
			const { offsetWidth, offsetHeight } = ref.current;
			const diagonal = Math.sqrt(offsetWidth ** 2 + offsetHeight ** 2);
			const size = 0.5 * diagonal * progress ** 2;

			const precision = 0.125;
			const roundedSize = Math.round(size / precision) * precision;
			setSize(roundedSize);
		} else {
			setSize(0);
		}
	}, [progress]);

	useEffect(() => {
		if (ref.current) {
			ref.current.style.clipPath = `circle(${size}px at center)`;
		}
	}, [size]);

	return (
		<div
			ref={ref}
			className={tailwindCascade("w-full h-full p-0 m-0 transform relative z-0", className)}
			{...props}
		>
			{children}
		</div>
	);
}

function Blur({ className, children, progress, ...props }) {
	const ref = useRef(null);
	const [size, setSize] = useState(100);

	useEffect(() => {
		if (ref.current) {
			const { offsetWidth, offsetHeight } = ref.current;
			const diagonal = Math.sqrt(offsetWidth ** 2 + offsetHeight ** 2);
			const size = 0.1 * diagonal * (1 - Math.sqrt(progress));

			const precision = 0.001;
			const roundedSize = Math.round(size / precision) * precision;
			setSize(roundedSize === 0 && progress === 0 ? 100 : roundedSize);
		} else {
			setSize(100);
		}
	}, [progress]);

	useEffect(() => {
		if (ref.current) {
			ref.current.style.filter = `blur(${size}px)`;
		}
	}, [size]);

	return (
		<div
			ref={ref}
			className={tailwindCascade("w-full h-full relative z-0 p-0 m-0 transform-gpu", className)}
			{...props}
		>
			{children}
		</div>
	);
}

export default function Reveal({ type = null, children, className, progress, ...props }) {
	if (
		[
			REVEAL_TYPE_GRID_3X4,
			REVEAL_TYPE_GRID_6X8,
			REVEAL_TYPE_GRID_3X4_SEQUENTIAL,
			REVEAL_TYPE_GRID_6X8_SEQUENTIAL,
		].includes(type)
	) {
		const [numRows, numCols] = [REVEAL_TYPE_GRID_3X4, REVEAL_TYPE_GRID_3X4_SEQUENTIAL].includes(type)
			? [3, 4]
			: [6, 8];
		const seed = [REVEAL_TYPE_GRID_3X4, REVEAL_TYPE_GRID_6X8].includes(type) ? props.seed : null;

		return (
			<Grid className={className} progress={progress} numCols={numCols} numRows={numRows} seed={seed}>
				{children}
			</Grid>
		);
	} else if (type === REVEAL_TYPE_ZOOM_20X) {
		return (
			<Zoom className={className} progress={progress}>
				{children}
			</Zoom>
		);
	} else if (type === REVEAL_TYPE_IRIS) {
		return (
			<Iris className={className} progress={progress}>
				{children}
			</Iris>
		);
	} else if (type === REVEAL_TYPE_BLUR) {
		return (
			<Blur className={className} progress={progress}>
				{children}
			</Blur>
		);
	}

	return <>{children}</>;
}
