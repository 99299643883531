import React, { useState, useCallback, useEffect, useRef, memo } from "react";
import isEqual from "lodash/isEqual";

import VolumeIcon from "@/images/icons/icon-volume.svg";
import VolumeMutedIcon from "@/images/icons/icon-volume-muted.svg";

function emptyFunction() {}

function YoutubeMuteButton({ muted = false, onChange, ...props }) {
	const ref = useRef(null);

	const onChangeRef = useRef(onChange);
	useEffect(() => void (onChangeRef.current = onChange), [onChange]);

	const [isMuted, setIsMuted] = useState(muted);
	useEffect(() => void setIsMuted(muted), [muted]);

	const onClick = useCallback(
		(event) => {
			event.stopPropagation();
			setIsMuted(!isMuted);
		},
		[isMuted]
	);

	useEffect(() => {
		if (onChangeRef.current) {
			onChangeRef.current(isMuted);
		}
	}, [isMuted]);

	return (
		<div {...props}>
			<div ref={ref} className="absolute bottom-0 right-0">
				<div className="md:border-3 relative flex flex-col items-center bg-white border-2 rounded-full">
					<button
						className="md:w-11 md:h-11 flex items-center justify-center w-4 h-4"
						onClick={onClick}
						onFocus={emptyFunction}
					>
						{isMuted ? (
							<VolumeMutedIcon className="md:w-8 md:h-8 w-4 h-4 text-black" />
						) : (
							<VolumeIcon className="md:w-8 md:h-8 w-4 h-4 text-black" />
						)}
					</button>
				</div>
			</div>
		</div>
	);
}

const MemorizedYoutubeMuteButton = memo(YoutubeMuteButton, isEqual);
export default MemorizedYoutubeMuteButton;
