import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import isEqual from "lodash/isEqual";
import isFinite from "lodash/isFinite";
import { useImmer } from "use-immer";

import Reveal from "@/components/Reveal";
import Spinner from "@/components/Spinner";
import YoutubeMuteButton from "@/components/interactives/YoutubeMuteButton";
import YoutubeVolumeSlider from "@/components/interactives/YoutubeVolumeSlider";

import canAutoPlay from "@/helpers/canAutoPlay";
import isApp from "@/helpers/isApp";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import trans from "@/helpers/trans";
import { clearTimeout, setTimeout } from "@/helpers/workerTimers";
import { youtubeExists } from "@/helpers/youtube";

import useBrowser from "@/hooks/useBrowser";
import useForwardRef from "@/hooks/useForwardRef";

import YoutubeLogo from "@/images/logo/logo-youtube.svg";

import YouTubePlayer from "@/youtubePlayer/YouTubePlayer";

const UNSTARTED = "unstarted";
const ENDED = "ended";
const PAUSED = "paused";
const CUED = "cued";
const PLAYING = "playing";
const BUFFERING = "buffering";
const ERROR = "error";
const UNPLAYABLE = "unplayable";
const TIMEUPDATE = "timeupdate";
const AUTOPLAYBLOCKED = "autoplayblocked";

const YOUTUBE_PLAYER_CUE_TIMEOUT = 5000;
const YOUTUBE_PLAYER_PLAYING_TIMEOUT = 5000;
const YOUTUBE_PLAYER_PAUSE_TIMEOUT = 500;
const YOUTUBE_PLAYER_SHOW_MUTE_TIMEOUT = 1000;

function getElementSize(element) {
	if (element && isFinite(element.clientWidth) && isFinite(element.clientHeight)) {
		return { width: element.clientWidth, hheight: element.clientHeight };
	} else {
		return null;
	}
}

class YoutubePlayer {
	constructor({
		element,
		videoId,
		start = 0,
		duration = Number.MAX_VALUE,
		loop = false,
		volume = 0.5,
		mute = false,
		onLoad = null,
		onStart = null,
		onStarted = null,
		onEnd = null,
		onUnplayable = null,
		onAutoplayBlocked = null,
		onError = null,
		onMute = null,
		onUnMute = null,
		onShowMute = null,
		onHideMute = null,
		onDesiredState = null,
		volumeMultiplier = 0.5,
	}) {
		const player = (this._player = new YouTubePlayer(element, {
			captions: false,
			controls: false,
			keyboard: false,
			fullscreen: false,
			annotations: false,
			related: false,
			playsInline: true,
			timeupdateFrequency: Math.round(1000 / 10),
			host: "https://www.youtube-nocookie.com",
		}));

		this._videoId = videoId;
		this._start = start;
		this._duration = duration;
		this._loop = loop;
		this._volume = volume;
		this._mute = mute;

		this._currentTime = this._start;
		this._previousTime = null;
		this._playQueued = false;
		this._desiredState = PAUSED;
		this._started = false;
		this._initialPlay = false;
		this._endReached = false;
		this._isMuted = false; // Initial predict that user can play unmuted
		this._showMute = false;

		this._volumeMultiplier = volumeMultiplier;

		this._onLoad = onLoad;
		this._onStart = onStart;
		this._onStarted = onStarted;
		this._onError = onError;
		this._onEnd = onEnd;
		this._onUnplayable = onUnplayable;
		this._onAutoplayBlocked = onAutoplayBlocked;
		this._onMute = onMute;
		this._onUnMute = onUnMute;
		this._onDesiredState = onDesiredState;
		this._onShowMute = onShowMute;
		this._onHideMute = onHideMute;

		this._bindedOnError = null;
		this._bindedOnUnplayable = null;
		this._bindedOnCuedInitial = null;
		this._bindedOnPlayingInitial = null;
		this._bindedOnTimeUpdate = null;
		this._bindedOnPlaying = null;
		this._bindedOnAutoplayBlocked = null;

		this._cueTimeout = null;
		this._playingTimeout = null;
		this._showMuteTimeout = null;
		this._pauseUnMuteTimeout = null;
		this._waitUntilLoadedCallback = null;

		youtubeExists(videoId)
			.then(() => {
				/*
				player.on(UNSTARTED, () => void console.log(`YouTube Event: ${UNSTARTED}`, element));
				player.on(ENDED, () => void console.log(`YouTube Event: ${ENDED}`), element);
				player.on(PLAYING, () => void console.log(`YouTube Event: ${PLAYING}`), element);
				player.on(PAUSED, () => void console.log(`YouTube Event: ${PAUSED}`), element);
				player.on(BUFFERING, () => void console.log(`YouTube Event: ${BUFFERING}`), element);
				player.on(CUED, () => void console.log(`YouTube Event: ${CUED}`), element);
				player.on(ERROR, () => void console.log(`YouTube Event: ${ERROR}`), element);
				player.on(UNPLAYABLE, () => void console.log(`YouTube Event: ${UNPLAYABLE}`), element);
				player.on(
					TIMEUPDATE,
					(currentTime) => void console.log(`YouTube Event: ${TIMEUPDATE}, ${currentTime}`)
				);
				*/
				player.on(ERROR, (this._bindedOnError = () => this._onError()));
				player.on(UNPLAYABLE, (this._bindedOnUnplayable = () => this._onUnplayable()));
				player.on(AUTOPLAYBLOCKED, (this._bindedOnAutoplayBlocked = () => this._onAutoplayBlocked()));
				player.on(CUED, (this._bindedOnCuedInitial = () => this._onCuedInitial()));

				player.mute();
				player.load(this._videoId, false, this.start);

				this._cueTimeout = setTimeout(() => {
					this._cueTimeout = null;

					this._disposeEventsAndTimeouts();

					this._loaded = true;
					if (this._onLoad) {
						this._onLoad();
					}

					const playQueued = this._playQueued;
					this._playQueued = false;

					if (playQueued && !this._endReached) {
						this.play();
					}

					if (this._waitUntilLoadedCallback) {
						this._waitUntilLoadedCallback();
						this._waitUntilLoadedCallback = null;
					}
				}, YOUTUBE_PLAYER_CUE_TIMEOUT);
			})
			.catch(() => {
				if (this._onError) {
					this._onError();
				}
			});
	}

	get desiredState() {
		return this._desiredState;
	}

	get start() {
		return this._start;
	}

	get end() {
		const player = this._player;
		if (player) {
			if (this._duration > 0) {
				return Math.max(Math.min(this.start + this._duration, player.getDuration() - 0.25), 0.25);
			} else {
				return Math.max(0.25, player.getDuration() - 0.25);
			}
		}
		return Number.MAX_VALUE;
	}

	_onPlaying() {
		if (!this._loaded) {
			this._loaded = true;
			if (this._onLoad) {
				this._onLoad();
			}
		}

		if (this._waitUntilLoadedCallback) {
			this._waitUntilLoadedCallback();
			this._waitUntilLoadedCallback = null;
		}
	}

	_onCuedInitial() {
		this._disposeEventsAndTimeouts();

		const player = this._player;
		if (player) {
			player.on(PLAYING, (this._bindedOnPlayingInitial = () => this._onPlayingInitial()));
			this._currentTime = this.start;
			player.seek(this._currentTime);

			this._playingTimeout = setTimeout(() => {
				this._playingTimeout = null;

				this._disposeEventsAndTimeouts();

				if (!this._loaded) {
					this._loaded = true;
					if (this._onLoad) {
						this._onLoad();
					}
				}

				const playQueued = this._playQueued;
				this._playQueued = false;

				if (playQueued && !this._endReached) {
					this.play();
				}

				if (this._waitUntilLoadedCallback) {
					this._waitUntilLoadedCallback();
					this._waitUntilLoadedCallback = null;
				}
			}, YOUTUBE_PLAYER_PLAYING_TIMEOUT);
		}
	}

	_onPlayingInitial() {
		this._disposeEventsAndTimeouts();

		const playQueued = this._playQueued;
		this._playQueued = false;

		if (!playQueued) {
			this._desiredState = PAUSED;
			if (this._onDesiredState) {
				this._onDesiredState(this._desiredState);
			}
			const player = this._player;
			if (player) {
				this._currentTime = player.getCurrentTime();
				player.seek(this.start);
				player.pause();
			}
		}

		if (!this._loaded) {
			this._loaded = true;
			if (this._onLoad) {
				this._onLoad();
			}
		}

		if (playQueued && !this._endReached) {
			this.play();
		}

		if (this._waitUntilLoadedCallback) {
			this._waitUntilLoadedCallback();
			this._waitUntilLoadedCallback = null;
		}

		const player = this._player;
		if (player) {
			player.on(PLAYING, () => (this._bindedOnPlaying = () => this._onPlaying()));
		}
	}

	_onTimeUpdate(currentTime) {
		const player = this._player;
		if (player) {
			const seconds = (this._currentTime = currentTime);
			const start = this.start;
			const end = this.end;

			if (
				(this._previousTime !== null && this._currentTime > 0) ||
				(this._previousTime !== null && this._previousTime !== this._currentTime)
			) {
				if (!this._started) {
					this._started = true;
					if (this._onStarted) {
						this._onStarted();
					}
				}
			}
			this._previousTime = this._currentTime;

			if (seconds < start) {
				player.seek((this._currentTime = start));
			} else if (seconds >= end) {
				if (this._loop) {
					player.seek((this._currentTime = start));
				} else {
					this.pause();

					if (!this._endReached) {
						this._endReached = true;

						if (this._onEnd) {
							this._onEnd();
						}
					}
				}
			}
		}
	}

	play(muted = false) {
		this._desiredState = PLAYING;
		if (this._onDesiredState) {
			this._onDesiredState(this._desiredState);
		}

		if (this._showMuteTimeout) {
			clearTimeout(this._showMuteTimeout);
			this._showMuteTimeout = null;
		}

		this._showMute = false;
		if (this._onHideMute) {
			this._onHideMute();
		}

		if (this._endReached) {
			this.pause();
		} else {
			if (!this._loaded) {
				this._playQueued = true;
			} else {
				this._disposeEventsAndTimeouts();

				const player = this._player;
				if (player) {
					this._previousTime = null;
					player.on(
						TIMEUPDATE,
						(this._bindedOnTimeUpdate = (currentTime) => this._onTimeUpdate(currentTime))
					);

					if (muted) {
						player.mute();
						this._emitMuteEvent((this._isMuted = true));
					} else {
						this.unMute();
						this._pauseUnMuteTimeout = setTimeout(() => {
							this._pauseUnMuteTimeout = null;

							if (player.getState() === PAUSED) {
								this._disposeEventsAndTimeouts();
								this.play(true);
							}
						}, YOUTUBE_PLAYER_PAUSE_TIMEOUT);
					}

					this._updateVolume();

					player.seek(this._currentTime);
					player.play();

					this._showMuteTimeout = setTimeout(() => {
						this._showMuteTimeout = null;

						this._showMute = true;
						if (this._onShowMute) {
							this._onShowMute();
						}
					}, YOUTUBE_PLAYER_SHOW_MUTE_TIMEOUT);

					if (this._onStart) {
						this._onStart();
					}
				}
			}
		}
	}

	pause() {
		this._desiredState = PAUSED;
		if (this._onDesiredState) {
			this._onDesiredState(this._desiredState);
		}

		if (!this._loaded) {
			this._playQueued = false;
		} else {
			this._disposeEventsAndTimeouts();

			const player = this._player;
			if (player) {
				this._currentTime = player.getCurrentTime();
				player.pause();
			}
		}
	}

	waitUntilLoaded() {
		if (this._waitUntilLoadedCallback) {
			this._waitUntilLoadedCallback(true);
			this._waitUntilLoadedCallback = null;
		}

		return new Promise((resolve, reject) => {
			if (this._loaded) {
				resolve();
			} else {
				this._waitUntilLoadedCallback = (error = false) => {
					if (!error) {
						resolve();
					} else {
						reject();
					}
				};
			}
		});
	}

	_updateVolume() {
		const player = this._player;
		if (player) {
			if (!this._mute) {
				player.setVolume(Math.round(this._volume * this._volumeMultiplier * 100));
			} else {
				player.mute();
			}
			if (this._desiredState === PLAYING) {
				player.play();
			}
		}
	}

	_emitMuteEvent(muted) {
		if (muted) {
			if (this._onMute) {
				this._onMute();
			}
		} else {
			if (this._onUnMute) {
				this._onUnMute();
			}
		}
	}

	mute() {
		const player = this._player;
		if (player) {
			player.mute();
			this._emitMuteEvent((this._isMuted = true));
		}
	}

	unMute() {
		this._updateVolume();
		if (!this._mute) {
			const player = this._player;
			if (player) {
				player.unMute();
				this._emitMuteEvent((this._isMuted = false));
			}
		} else {
			this.mute();
		}
	}

	_disposeEventsAndTimeouts() {
		const player = this._player;
		if (player) {
			if (this._bindedOnCuedInitial) {
				player.off(CUED, this._bindedOnCuedInitial);
				this._bindedOnCuedInitial = null;
			}

			if (this._bindedOnPlayingInitial) {
				player.off(PLAYING, this._bindedOnPlayingInitial);
				this._bindedOnPlayingInitial = null;
			}

			if (this._bindedOnTimeUpdate) {
				player.off(TIMEUPDATE, this._bindedOnTimeUpdate);
				this._bindedOnTimeUpdate = null;
			}
		}

		if (this._cueTimeout) {
			clearTimeout(this._cueTimeout);
			this._cueTimeout = null;
		}

		if (this._playingTimeout) {
			clearTimeout(this._playingTimeout);
			this._playingTimeout = null;
		}

		if (this._showMuteTimeout) {
			clearTimeout(this._showMuteTimeout);
			this._showMuteTimeout = null;
		}

		if (this._pauseUnMuteTimeout) {
			clearTimeout(this._pauseUnMuteTimeout);
			this._pauseUnMuteTimeout = null;
		}
	}

	set volume(volume) {
		this._volume = volume;
		this._updateVolume();
	}

	set volumeMultiplier(volumeMultiplier) {
		this._volumeMultiplier = volumeMultiplier;
		this._updateVolume();
	}

	dispose() {
		if (this._image) {
			this._image.onload = this._image.onerror = null;
			this._image = null;
		}

		const player = this._player;

		if (this._bindedOnPlaying) {
			if (player) {
				player.off(PLAYING, this._bindedOnPlaying);
			}
			this._bindedOnPlaying = null;
		}

		if (this._bindedOnError) {
			if (player) {
				player.off(ERROR, this._bindedOnError);
			}
			this._bindedOnError = null;
		}

		if (this._bindedOnUnplayable) {
			if (player) {
				player.off(UNPLAYABLE, this._bindedOnUnplayable);
			}
			this._bindedOnUnplayable = null;
		}

		this._disposeEventsAndTimeouts();

		if (player) {
			player.destroy();
		}
	}
}

const YoutubePlayer2 = forwardRef(function YoutubePlayer2(
	{
		videoId,
		loop = false,
		start = 0,
		duration = Number.MAX_VALUE,
		volume = 1, // Input volume is between 0...1
		showVolumeSlider = true,
		mute = false,
		dim = false,
		onLoad = null,
		onStart = null,
		onEnd = null,
		onError = null,
		onClick = null,
		volumeMultiplier,
		reveal = false,
		revealType,
		revealSeed,
		revealProgress,
		isGamePaused = false,
		...props
	},
	forwardedRef
) {
	const ref = useForwardRef(forwardedRef);
	const elementRef = useRef(null);
	const youtubePlayerRef = useRef(null);
	const containerRef = useRef(null);

	const [state, updateState] = useImmer({
		loaded: false,
		error: false,
		isMuted: false, // Initial predict that user can play unmuted
		desiredState: PAUSED,
		unMuted: false,
		showMute: false,
		started: false,
	});

	const browser = useBrowser();

	const [containerSize, setContainerSize] = useState(null);
	const [isThumbnail, setIsThumbnail] = useState(false);

	const [canAutoPlayMuted, setCanAutoPlayMuted] = useState(true); // Default to autoplay muted is supported
	useEffect(() => {
		if (isApp) {
			setCanAutoPlayMuted(true);
		} else {
			canAutoPlay.video({ timeout: 1000, muted: true }).then(({ result }) => void setCanAutoPlayMuted(!!result));
		}
	}, []);

	const onLoadRef = useRef(onLoad);
	useEffect(() => void (onLoadRef.current = onLoad), [onLoad]);
	const onStartRef = useRef(onStart);
	useEffect(() => void (onStartRef.current = onStart), [onStart]);
	const onEndRef = useRef(onEnd);
	useEffect(() => void (onEndRef.current = onEnd), [onEnd]);
	const onErrorRef = useRef(onClick);
	useEffect(() => void (onErrorRef.current = onError), [onError]);
	const onClickRef = useRef(onClick);
	useEffect(() => void (onClickRef.current = onClick), [onClick]);

	const volumeRef = useRef(volume);
	useEffect(() => void (volumeRef.current = volume), [volume]);
	const muteRef = useRef(mute);
	useEffect(() => void (muteRef.current = mute), [mute]);
	const volumeMultiplierRef = useRef(volumeMultiplier);

	useImperativeHandle(
		ref,
		() => ({
			play() {
				if (youtubePlayerRef.current) {
					youtubePlayerRef.current.play();
				}
			},
			pause() {
				if (youtubePlayerRef.current) {
					youtubePlayerRef.current.pause();
				}
			},
			unMute() {
				if (youtubePlayerRef.current) {
					youtubePlayerRef.current.unMute();
				}
			},
			setVolume(volume) {
				if (youtubePlayerRef.current) {
					youtubePlayerRef.current.volume = volume / 100; // Volume is between 0...100
				}
			},
			getDesiredState() {
				if (youtubePlayerRef.current) {
					return youtubePlayerRef.current.desiredState;
				}
				return PAUSED;
			},
			untilReady() {
				if (youtubePlayerRef.current) {
					return youtubePlayerRef.current.waitUntilLoaded();
				}
				return new Promise((_resolve, reject) => void reject());
			},
		}),
		[]
	);

	useEffect(() => {
		const youtubePlayer = (youtubePlayerRef.current = new YoutubePlayer({
			element: elementRef.current,
			videoId,
			start: start,
			duration: duration,
			loop: !!loop,
			volume: volumeRef.current,
			mute: !!muteRef.current,
			onLoad: () => {
				updateState((draft) => void (draft.loaded = true));

				if (onLoadRef.current) {
					onLoadRef.current();
				}
			},
			onStart: () => {
				if (onStartRef.current) {
					onStartRef.current();
				}
			},
			onStarted: () => void updateState((draft) => void (draft.started = true)),
			onEnd: () => {
				if (onEndRef.current) {
					onEndRef.current();
				}
			},
			onError: () => {
				updateState((draft) => void (draft.error = true));

				if (onErrorRef.current) {
					onErrorRef.current();
				}
			},
			onUnplayable: () => {
				updateState((draft) => void (draft.error = true));

				if (onErrorRef.current) {
					onErrorRef.current();
				}
			},
			onAutoplayBlocked: () => {
				setCanAutoPlayMuted(false);
			},
			onDesiredState: (desiredState) => void updateState((draft) => void (draft.desiredState = desiredState)),
			onMute: () => void updateState((draft) => void (draft.isMuted = true)),
			onUnMute: () => void updateState((draft) => void (draft.isMuted = false)),
			onShowMute: () => void updateState((draft) => void (draft.showMute = true)),
			onHideMute: () => void updateState((draft) => void (draft.showMute = false)),
			volumeMultiplier: volumeMultiplierRef.current,
		}));

		updateState((draft) => {
			draft.loaded = false;
			draft.error = false;
			draft.isMuted = false; // Initial predict that user can play unmuted
			draft.desiredState = PAUSED;
			draft.unMuted = false;
			draft.showMute = false;
			draft.started = false;
		});

		return () => {
			youtubePlayer.dispose();
			youtubePlayerRef.current = null;
		};
	}, [updateState, duration, loop, start, videoId]);

	useEffect(() => {
		youtubePlayerRef.current.volumeMultiplier = volumeMultiplierRef.current = volumeMultiplier;
	}, [volumeMultiplier]);

	useEffect(() => {
		if (youtubePlayerRef.current) {
			youtubePlayerRef.current.volume = volume;
		}
	}, [volume]);

	useEffect(() => {
		if (youtubePlayerRef.current) {
			if (mute) {
				youtubePlayerRef.current.mute();
			} else {
				youtubePlayerRef.current.unMute();
			}
		}
	}, [mute]);

	useEffect(() => {
		const container = containerRef.current;
		setContainerSize(getElementSize(container));

		if (container) {
			const resizeObserver = new ResizeObserver((entries) => {
				setContainerSize(getElementSize(entries[0].target));
			});

			resizeObserver.observe(container);

			return () => void resizeObserver.unobserve(container);
		}
	}, []);

	useEffect(() => void setIsThumbnail(!(containerSize && containerSize.width >= 320)), [containerSize]);

	const onClickUnMute = useCallback(
		(event) => {
			event.stopPropagation();
			if (youtubePlayerRef.current) {
				youtubePlayerRef.current.unMute();
			}
			updateState((draft) => void (draft.unMuted = true));
		},
		[updateState]
	);

	const onClickPlay = useCallback(() => {
		if (youtubePlayerRef.current) {
			youtubePlayerRef.current.play();
		}
	}, []);

	const onChangeYoutubeMuteButton = useCallback((value) => {
		if (youtubePlayerRef.current) {
			if (value) {
				youtubePlayerRef.current.mute();
			} else {
				youtubePlayerRef.current.unMute();
			}
		}
	}, []);

	const onChangeYoutubeVolumeSlider = useCallback((value) => {
		if (youtubePlayerRef.current) {
			youtubePlayerRef.current.volumeMultiplier = value / 100;
		}
	}, []);

	const onClickContainer = useCallback(
		(event) => {
			event.preventDefault();
			event.stopPropagation();

			if (youtubePlayerRef.current && state.desiredState !== PLAYING) {
				youtubePlayerRef.current.play();
			}

			if (onClickRef.current) {
				onClickRef.current();
			}
		},
		[state.desiredState]
	);

	const isIOs = useMemo(() => !!(browser && browser.getOSName(true) === "ios"), [browser]);

	const showVolumeSliderFragment = useMemo(
		() =>
			!!(
				!isIOs &&
				!isThumbnail &&
				state.started &&
				showVolumeSlider &&
				canAutoPlayMuted &&
				!mute &&
				!state.isMuted &&
				state.showMute &&
				state.loaded &&
				!state.error &&
				state.desiredState === PLAYING
			),
		[
			isThumbnail,
			canAutoPlayMuted,
			isIOs,
			mute,
			showVolumeSlider,
			state.desiredState,
			state.error,
			state.isMuted,
			state.loaded,
			state.showMute,
			state.started,
		]
	);

	const showMuteButtonFragment = useMemo(
		() =>
			!!(
				isIOs &&
				!isThumbnail &&
				state.started &&
				showVolumeSlider &&
				canAutoPlayMuted &&
				!mute &&
				(state.unMuted || !state.isMuted) &&
				state.showMute &&
				state.loaded &&
				!state.error &&
				state.desiredState === PLAYING
			),
		[
			isThumbnail,
			canAutoPlayMuted,
			isIOs,
			mute,
			showVolumeSlider,
			state.desiredState,
			state.error,
			state.isMuted,
			state.loaded,
			state.showMute,
			state.started,
			state.unMuted,
		]
	);

	const showClickToUnmuteFragment = useMemo(
		() =>
			!!(
				!isThumbnail &&
				!mute &&
				!state.unMuted &&
				canAutoPlayMuted &&
				state.isMuted &&
				state.loaded &&
				!state.error &&
				!isGamePaused
			),
		[isThumbnail, canAutoPlayMuted, isGamePaused, mute, state.error, state.isMuted, state.loaded, state.unMuted]
	);

	const showClickToPlayFragment = useMemo(
		() =>
			!!(state.loaded && !state.error && state.desiredState === PLAYING && !state.started && !isGamePaused
				? !canAutoPlayMuted
				: false),
		[canAutoPlayMuted, isGamePaused, state.desiredState, state.error, state.loaded, state.started]
	);

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events
		<div ref={containerRef} role="button" tabIndex={0} className="relative" onClick={onClickContainer}>
			<div className="pb-16/9 relative z-0 w-full h-0 overflow-hidden bg-black">
				<div className="absolute top-0 left-0 w-full h-full">
					<div className="relative w-full h-full">
						<Reveal
							className="z-4 overflow-hidden"
							type={revealType}
							seed={revealSeed}
							progress={revealProgress}
						>
							<div
								className={tailwindCascade("absolute", "left-0", "w-full", "pointer-events-none", {
									"top-[-360px] h-[calc(100%+720px)]": !state.error,
									"top-0 h-full": state.error,
								})}
							>
								<div className="absolute top-0 left-0 w-full h-full" ref={elementRef}></div>
							</div>

							{!state.error && (
								<div
									className={tailwindCascade(
										"z-1",
										"absolute",
										"top-0",
										"left-0",
										"w-full",
										"h-full",
										{
											"bg-black": !state.loaded || !state.started,
										}
									)}
								></div>
							)}

							{!isThumbnail && !state.loaded && !state.started && !state.error && (
								<div className="left-1/2 top-1/2 z-2 absolute transform -translate-x-1/2 -translate-y-1/2">
									<Spinner className="w-12 h-12" />
								</div>
							)}

							{!isThumbnail && state.loaded && state.started && !state.error && (
								<div className="top-1 right-1 md:top-2 md:right-2 w-18 md:w-28 z-3 absolute pointer-events-auto">
									<a
										className="block p-2"
										href={`https://youtu.be/${videoId}`}
										target="_blank"
										rel="noreferrer"
									>
										<YoutubeLogo className="text-white" />
									</a>
								</div>
							)}

							{dim && (
								<div className="z-5 absolute inset-0 bg-black bg-opacity-50 pointer-events-none"></div>
							)}
						</Reveal>
					</div>
				</div>

				{showMuteButtonFragment && (
					<YoutubeMuteButton
						className="md:right-2 md:bottom-2 right-1 bottom-1 z-6 absolute pointer-events-auto"
						muted={state.isMuted}
						onChange={onChangeYoutubeMuteButton}
					/>
				)}

				<YoutubeVolumeSlider
					className={tailwindCascade("right-2 bottom-2 z-7 absolute pointer-events-auto", {
						hidden: !showVolumeSliderFragment,
					})}
					onChange={onChangeYoutubeVolumeSlider}
				/>

				{showClickToUnmuteFragment && (
					<div
						className={
							"absolute bottom-0 right-0 left-0 z-20 px-2 py-2 md:px-4 md:py-4 leading-tight text-white text-xs md:text-3xl text-center bg-black bg-opacity-80 pointer-events-auto"
						}
					>
						<span className="md:mr-4 inline-block mr-2">{trans("Playing video without sound.")}</span>
						<button className="underline" onClick={onClickUnMute}>
							{trans("Click here to unmute.")}
						</button>
					</div>
				)}

				{showClickToPlayFragment && (
					<div className="absolute inset-0 z-20 flex items-center justify-around pointer-events-auto">
						<button
							className="md:py-4 md:px-6 md:text-3xl bg-opacity-80 px-4 py-2 text-xs leading-tight text-center text-white underline bg-black rounded-md"
							onClick={onClickPlay}
						>
							{trans("Click here to play")}
						</button>
					</div>
				)}
			</div>
		</div>
	);
});

const MemorizedYoutubePlayer2 = memo(YoutubePlayer2, isEqual);
export default MemorizedYoutubePlayer2;
