import { getData } from "@/api/helpers";

export function youtubeExists(id) {
	return new Promise((resolve, reject) => {
		const image = new Image();
		const timeout = setTimeout(() => {
			image.onload = image.onerror = null;
			reject();
		}, 5000);
		image.src = `https://img.youtube.com/vi/${id}/mqdefault.jpg?t=${Date.now()}`;
		image.onload = () => {
			image.onload = image.onerror = null;
			clearTimeout(timeout);

			if (image.naturalWidth && image.naturalWidth >= 320) {
				return resolve();
			}

			if (image.width && image.width >= 320) {
				return resolve();
			}

			reject();
		};
		image.onerror = () => {
			image.onload = image.onerror = null;
			clearTimeout(timeout);
			reject();
		};
	});
}

export async function youtubePoll(id) {
	const result = await getData(`/youtube/${id}`);
	return result;
}
