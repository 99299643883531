import { useEffect, useState } from "react";
import Bowser from "bowser";

export default function useBrowser() {
	const [browser, setBrowser] = useState(null);

	useEffect(() => {
		if (typeof window !== "undefined") {
			try {
				setBrowser(Bowser.getParser(window.navigator.userAgent));
				return;
			} catch (error) {
				//
			}
		}

		setBrowser(null);
	}, []);

	return browser;
}
