import React from "react";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import { BLACK, PINK_LIGHT } from "@/colors";

export default function Spinner({ color = PINK_LIGHT, backgroundColor = BLACK, className }) {
	return (
		<div
			className={tailwindCascade(
				"animate-spin",
				"border-opacity-20",
				"border-6",
				"w-10",
				"h-10",
				"rounded-full",
				className
			)}
			style={{
				borderLeftColor: `${backgroundColor}33`,
				borderRightColor: `${backgroundColor}33`,
				borderTopColor: color,
				borderBottomColor: `${backgroundColor}33`,
			}}
		></div>
	);
}
