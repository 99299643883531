import React, { memo, useCallback, useEffect, useRef, useState } from "react";

import isEqual from "lodash/isEqual";

import Slider from "@/components/interactives/Slider";

import { tailwindCascade } from "@/helpers/tailwindCascade";

import VolumeIcon from "@/images/icons/icon-volume.svg";

import useSettingsStore from "@/stores/settings";

function emptyFunction() {}

function isInside(element, x, y) {
	if (element) {
		const boundingClientRect = element.getBoundingClientRect();
		if (
			x < boundingClientRect.x ||
			x > boundingClientRect.x + boundingClientRect.width ||
			y < boundingClientRect.y ||
			y > boundingClientRect.y + boundingClientRect.height
		) {
			return false;
		} else {
			return true;
		}
	}
	return false;
}

function YoutubeVolumeSlider({ onChange, ...props }) {
	const ref = useRef(null);

	const onChangeRef = useRef(onChange);
	useEffect(() => void (onChangeRef.current = onChange), [onChange]);

	const setSettingsStore = useSettingsStore((state) => state.set);
	const value = useSettingsStore((state) => state["mediaVolume"]) * 100;

	const setValue = useCallback(
		(value) => {
			setSettingsStore((draft) => {
				draft.mediaVolume = value * 0.01;
			});
		},
		[setSettingsStore]
	);

	const [active, setActive] = useState(false);

	const onClick = useCallback((event) => void setActive(isInside(ref.current, event.clientX, event.clientY)), []);
	const onMouseOver = useCallback(() => void setActive(true), []);

	useEffect(() => {
		if (active) {
			let locked = false;
			const onMouseMove = (event) => {
				if (ref.current && !locked) {
					if (!isInside(ref.current, event.clientX, event.clientY)) {
						document.removeEventListener("mousemove", onMouseMove);
						setActive(false);
					}
				}
			};
			const onMouseDown = () => void (locked = true);
			const onMouseUp = (event) => {
				locked = false;
				onMouseMove(event);
			};

			document.addEventListener("mousemove", onMouseMove);
			document.addEventListener("mousedown", onMouseDown);
			document.addEventListener("mouseup", onMouseUp);
			return () => {
				document.removeEventListener("mousemove", onMouseMove);
				document.removeEventListener("mousedown", onMouseDown);
				document.removeEventListener("mouseup", onMouseUp);
			};
		}
	}, [active]);

	useEffect(() => void (onChangeRef.current && onChangeRef.current(value)), [value]);

	return (
		<div {...props}>
			<div ref={ref} className="absolute bottom-0 right-0">
				<div className="border-3 relative flex flex-col items-center bg-white rounded-full">
					<div
						className={tailwindCascade("md:w-11", "relative", "w-8", "overflow-hidden", "h-[8.5rem]", {
							block: active,
							hidden: !active,
						})}
					>
						<div className="md:h-11 top-32 absolute left-0 w-32 h-8 origin-top-left transform -rotate-90">
							<div className="w-28 md:h-11 relative h-8 flex flex-col justify-center items-center">
								<Slider
									className="black-slider absolute md:top-[9px] top-[3px] left-0"
									value={value}
									setValue={setValue}
									min={0}
									max={100}
								/>
							</div>
						</div>
					</div>
					<button
						className="md:w-11 md:h-11 flex items-center justify-center w-8 h-8"
						onClick={onClick}
						onMouseOver={onMouseOver}
						onFocus={emptyFunction}
					>
						<VolumeIcon className="md:w-8 md:h-8 w-6 h-6 text-black" />
					</button>
				</div>
			</div>
		</div>
	);
}

const MemorizedYoutubeVolumeSlider = memo(YoutubeVolumeSlider, isEqual);
export default MemorizedYoutubeVolumeSlider;
